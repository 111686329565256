import {
  ClearCart,
  CreateOrder,
  DeleteGoods2Carts,
  GetCartList,
  getDownCartCode,
  UpdateGoodsNum,
} from "@/api/carts/carts";

export default {
  data() {
    return {
      querySkuCode: "", //查询商品代码
      showCartListInfo: {
        scroll: "", //高
        showSpan: false,
        listClass: "showCartListInfo2",
      }, //显示购物车的内容
      carts: [],
      tableData: [],
      multipleSelection: [],
      hasMess: false,
      message: "",
      dialogVisible: false,
      textarea: "",
      //importError: [],
      //导出失败信息
      importGoodsDataError: [],
      //导入成功
      importGoodsDataSuccess: [],
      isLoading: {
        table: false,
        empty: false,
      },
      //选择打包方式
      optionsBreakUp: {},
      goodsTypeValue: "",
      GoodsTypes: [],
      CartQuery: {
        big_type: 0,
        middle_type: 0,
        small_type: 0,
      },
      cartTotal: {
        list: "购物车有 5 款商品类型，共有 96 件",
        total: 0,
      },
      pages: {
        currentPage: 1,
        size: 20,
        total: 0,
      },
      hasShowSubmitOrderInput: true, //显示提交按钮
      submitOrderIng: true, //显示导入按钮
      visible: false,
      emptyVisible: false,
      showTotal: "showDivTotal",
    };
  },
  created() {
    this.getData();
    this.showOrderTypeLang();
    this.productType();
  },
  watch: {
    message() {
      if (this.hasMess) {
        this.$message.success(this.message);
        this.getData();
      }
    },
    goodsTypeValue() {
      this.CartQuery={
        big_type: 0,
        middle_type: 0,
        small_type: 0,
      };
      let goodsTypeArr=this.goodsTypeValue;
      if (goodsTypeArr.length == 1) {
        this.CartQuery.big_type=parseInt(goodsTypeArr[0]);
      }
      if (goodsTypeArr.length == 2) {
        this.CartQuery.middle_type=parseInt(goodsTypeArr[1]);
      }
      if (goodsTypeArr.length == 3) {
        this.CartQuery.small_type=parseInt(goodsTypeArr[2]);
      }
      this.getData();
    },
    querySkuCode() {
      this.CartQuery.sku=this.querySkuCode;
      this.getData();
    },
  },
  mounted() {
    window.addEventListener("scroll", this.menu, true); //监听滚动
  },
  methods: {
    //显示跟随屏幕滚动
    menu() {
      this.showCartListInfo.scroll=
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.showCartListInfo.scroll > 240) {
        this.showCartListInfo.listClass="showCartListInfo";
        this.showCartListInfo.showSpan=true;

      } else {
        this.showCartListInfo.listClass="showCartListInfo2";
        this.showCartListInfo.showSpan=false;

      }
    },
    handleSizeChange(val) {
      this.pages.size=val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pages.currentPage=val;
      this.getData();
    },
    //翻页部分 end

    //获取购物车参数
    getData() {
      this.CartQuery.page=this.pages.currentPage;
      this.CartQuery.page_size=this.pages.size;
      GetCartList(this.CartQuery).then((res) => {
        this.$emit("initCart");
        let respData=res.data.data;
        const statisticsData=res.data.data.cartStatistics;
        this.tableData=this.filterGoodsList(respData.data);
        if (this.$i18n.locale == "zh-CN") {
          this.cartTotal.list=
            "购物车有 " +
            statisticsData.big_type +
            " 款商品类型，共有 " +
            statisticsData.num +
            " 件";
        } else {
          this.cartTotal.list=
            "There are " +
            statisticsData.big_type +
            " product types in the shopping cart, a total of " +
            statisticsData.num +
            " items";
        }
        this.cartTotal.total=respData.price.toFixed(2);
        this.pages.currentPage=respData.page;
        this.pages.total=respData.total;
        this.pages.size=respData.page_size;
      });
    },
    //productType 产品类型
    productType() {
      this.$axios.get("/w1/goodsType/ForCart").then((res) => {
        if (this.$i18n.locale === "zh-CN") {
          this.GoodsTypes=res.data.data.cn;
        } else {
          this.GoodsTypes=res.data.data.en;
        }
      });
    },
    //显示订单类型语言
    showOrderTypeLang() {
      this.optionsBreakUp={
        action: 2,
        list: [{
          value: 0,
          label: this.$t("Small Category"),
        },
          {
            value: 1,
            label: this.$t("Middle Category"),
          },
          {
            value: 2,
            label: this.$t("Large Category"),
          },
        ],
      };
    },
    //显示导入购物车
    ImportCartShow() {
      this.dialogVisible=true;
      this.importGoodsDataError=[];
      this.importGoodsDataSuccess=[];
    },
    //点击进入商品详情
    toGoodsInfoPage(sky) {
      let {
        href,
      }=this.$router.resolve({
        path: "/web/goodsDetail",
        query: {
          id: sky,
        },
      });
      window.open(href);
    },
    //导出购物车列表信息
    exportCarts() {
      getDownCartCode().then((res) => {
        //window.open();
        location.href=this.$root.DownUrl + "/c1/down/" + res.data.data;
      });
    },
    //提交订单
    submitOrder() {
      this.hasShowSubmitOrderInput=false; //限制重复提交的问题
      this.$confirm(this.$t("Are you sure about the order information"))
        .then(() => {
          let postList=[];
          for (let i=0; i < this.multipleSelection.length; i++) {
            postList.push(this.multipleSelection[i].item_no);
          }
          CreateOrder({
            goods: postList,
            order_pack_type: parseInt(this.optionsBreakUp.action),
          })
            .then(() => {
              this.$message.success(this.$t("Submitted successfully"));
              this.$router.push({
                path: "/web/orderCenter/salesGoodsList",
              });
              this.getData();
              this.$emit("initCart");
              this.hasShowSubmitOrderInput=true;
            })
            .catch(() => {
              this.hasShowSubmitOrderInput=true;
            });
        })
        .catch(() => {
          this.hasShowSubmitOrderInput=true;
        });
    },
    //导入购物车
    submitInerCart() {
      this.submitOrderIng=false;
      this.importGoodsDataError=[];
      this.importGoodsDataSuccess=[];
      let testList=this.textarea.split("\n");
      let postSkuList=[]; //需要提交的sku列表
      let postDataList=[]; //准备导入的sku数量

      for (let i=0; i < testList.length; i++) {
        if (testList[i]) {
          let theGoodsArr=testList[i].split(",");
          postDataList[theGoodsArr[0]]=theGoodsArr[1];
          postSkuList[i]=theGoodsArr[0];
        }
      }

      //先查询到对应的商品信息
      this.$axios.post("/w1/goods/GetGoodsInSku", { sku: postSkuList }).then((res) => {
        let resData=res.data.data;
        if (resData && resData.length > 0) {

          let canPostData=[]; //允许提交的商品编码
          for (let j=0; j < resData.length; j++) {
            let theGoods=resData[j];
            //如果海鼎库存少于规格不计算
            if (theGoods.stock_num < theGoods.purchase_spec) {
              canPostData.push({
                item_no: theGoods.item_no,
                number: parseInt(postDataList[theGoods.item_no]),
              });
            } else {
              if (postDataList[theGoods.item_no] % theGoods.purchase_spec !== 0) {
                let incorect=this.$t("Incorrect specifications"); //规格不正确
                this.importGoodsDataError.push(
                  "【" + theGoods.item_no + "】" + incorect,
                );
              } else {
                canPostData.push({
                  item_no: theGoods.item_no,
                  number: parseInt(postDataList[theGoods.item_no]),
                });
              }
            }
          }

          this.$axios
            .post("/w1/cart/all", {
              goods: canPostData,
            })
            .then((res) => {
              let respData=res.data.data;
              for (let i in respData.success) {
                let theD=respData.success[i];
                this.importGoodsDataSuccess.push(theD.sku + ":" + theD.msg);
              }
              for (let i in respData.error) {
                let theD=respData.error[i];
                this.importGoodsDataError.push(
                  theD.sku + ":" + this.$t("Exceed inventory quantity"),
                );
              }
              this.getData();
              this.submitOrderIng=true;
              this.textarea="";
            });
        } else {
          this.$message.error(this.$t("The entered product barcode information was not found"));
          this.submitOrderIng=true;
        }
      });
    },
    //删除一条
    deleteRow(rowInfo) {
      if (confirm(this.$t("Are you sure you want to delete this item"))) {
        DeleteGoods2Carts(rowInfo.item_no).then(() => {
          this.$message.success("successfully deleted");
          this.getData();
        });
      }
    },
    //清空购物车
    clearCart() {
      this.isLoading.empty=true;
      ClearCart().then(() => {
        this.$message.success("Cleared successfully");
        this.getData();
        this.isLoading.empty=false;
        this.visible=false;
      });
    },
    //修改购物车产品
    handleChange(currentValue, oldValue, rowData) {
      if (currentValue % rowData.spe !== 0) {
        this.$message.error(
          this.$t("Please change in multiples of specifications"),
        );
        this.$nextTick(() => {
          rowData.num=oldValue;
        });
      } else {
        UpdateGoodsNum(rowData.item_no, currentValue)
          .then(() => {
            this.$emit("initCart");
            this.$message.success(this.$t("Operation complete"));
            this.getData();
          })
          .catch((err) => {
            //console.log(err.response.data.message)
            let showMes="";
            if (err.response.data.message === "超出了库存") {
              showMes=this.$t("Out of stock");
            }
            this.$message.error(showMes);
            this.$nextTick(() => {
              rowData.num=oldValue;
            });
          });
      }
    },
    //过滤语言切换
    filterGoodsList(goodsData) {
      let resData=goodsData;
      for (let i=0; i < resData.length; i++) {
        resData[i].file_type_icon_list_arr=
          resData[i].file_type_icon_list.split(",");

        //语言判断
        if (this.$i18n.locale == "zh-CN") {
          resData[i].GoodsName=resData[i].item_name;
        } else {
          resData[i].GoodsName=resData[i].item_en;
        }
        resData[i].endTime=resData[i].created_at * 1000 + 60 * 60 * 36 * 1000;
      }
      return resData;
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    //选中参数
    handleSelectionChange(val) {
      this.multipleSelection=val;
    },
    //删除选中的商品
    clickDeleteSel() {
      this.hasMess=false;
      this.message="";
      let selNum=this.multipleSelection.length;
      if (selNum > 0) {
        for (let i=0; i < selNum; i++) {
          let theData=this.multipleSelection[i];
          DeleteGoods2Carts(theData.item_no).then(() => {
            if (i === selNum - 1) {
              this.hasMess=true;
              this.message="successfully deleted"; //删除成功
              this.emptyVisible=false;
            }
          });
        }
      } else {
        this.$message.warning("Please select the product to be deleted"); //请选择需要删除的商品
      }
    },
    //弹出层关闭
    handleClose() {
      return false;
    },
    //清空购物车提示框取消按钮
    hidePopover() {
      this.$refs.footer.click();
    },
  },
};
