<template>
  <div class="shoppingCart">
    <Header />
    <Middle @initCart="initCart" />
  </div>
</template>
<script>
import Header from "../shoppingCart/Header";
import Middle from "../shoppingCart/Middle";

export default {
  components: {
    Header,
    Middle
  },
  data: function() {
    return {};
  },
  methods: {
    //初始化
    initCart() {
      this.$emit("initCart");
    }
  }
};
</script>
<style lang="scss" scoped>
.orderCenter {
  width: 100%;
  background-color: #f5f8fa;
}
</style>
