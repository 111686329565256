<template>
	<div>
		<!-- 购物车列表 -->
		<div class="cartTableList">
			<div>
				<el-popover trigger="click" placement="top" v-model="emptyVisible">
					<p>{{ $t("Are you sure you want to delete selected items") }}?</p>
					<div style="text-align: center; margin: 0">
						<el-button size="mini" type="text" style="color: #218da0" @click="hidePopover">
							{{ $t("Cancel") }}
						</el-button>
						<el-button style="color: #218da0" size="mini" @click="clickDeleteSel">{{ $t("Confirm") }}
						</el-button>
					</div>
					<el-button type="primary" style="font-size: 16px" size="mini" slot="reference" class="xmvogueColor"
						@click.stop>{{ $t("Delete Selected Items") }}
					</el-button>
				</el-popover>

				<el-popover trigger="click" placement="top" v-model="visible">
					<p>{{ $t("Are you sure you want to empty the cart") }}?</p>
					<div style="text-align: center; margin: 0">
						<el-button size="mini" type="text" style="color: #218da0" @click="hidePopover">
							{{ $t("Cancel") }}
						</el-button>
						<el-button style="color: #218da0" size="mini" @click="clearCart" v-loading="isLoading.empty">
							{{ $t("Confirm") }}
						</el-button>
					</div>
					<el-button type="primary" style="font-size: 16px; margin: 0 10px" size="mini" slot="reference"
						class="xmvogueColor" @click.stop>
						{{ $t("Empty Cart") }}
					</el-button>
				</el-popover>

				<el-button type="infor" size="mini" class="bdXmColor" @click="ImportCartShow">{{ $t("Import Cart") }}</el-button>
				<el-button type="infor" size="mini" class="bdXmColor" @click="exportCarts">{{ $t("Export") }}</el-button>

				<span style="margin-left: 50px">{{ $t("Product Category") }}:</span>
				<el-cascader :placeholder="$t('Please Select Product Category')" size="medium" v-model="goodsTypeValue"
					:options="GoodsTypes" :props="{ checkStrictly: true }" clearable style="width: 260px">
				</el-cascader>
			</div>

			<div :class="showCartListInfo.listClass">
				<span style="margin: 0 40px" v-if="showCartListInfo.showSpan">{{$t("Continue Shopping") }}</span>
				{{ cartTotal.list }}
				<span class="totalPirce1" v-if="showCartListInfo.showSpan">
					{{ $t("Total") }}：{{ $t("Price symbol") }}{{ cartTotal.total }}
				</span>
				<el-button v-if="showCartListInfo.showSpan" size="medium" style="margin-left: 50px; width: 180px"
					@click="submitOrder">
					{{ $t("Proceed to Checkout") }}
				</el-button>
			</div>

			<div class="showDivTotal">
				{{ $t("Total") }}：{{ $t("Price symbol") }}{{ cartTotal.total }}
			</div>

			<div style="margin: 10px 0">
				<el-input placeholder="sku" size="big" style="width: 428px; margin: 0 10px 0 0" v-model="querySkuCode">
				</el-input>
				<el-select v-model="optionsBreakUp.action" :placeholder="$t('Select method of settlement')" size="big"
					style="margin-right: 10px">
					<el-option v-for="item in optionsBreakUp.list" :key="item.value" :label="item.label"
						:value="item.value">
					</el-option>
				</el-select>
				<el-button type="primary" size="big" class="xmvogueColor" @click="submitOrder"
					v-show="hasShowSubmitOrderInput">
					{{ $t("Proceed to Checkout") }}
				</el-button>
				<i class="el-icon-loading" v-show="hasShowSubmitOrderInput == false"></i>
			</div>

			<div style="border: 1px solid #218da0; margin-top: 5px">
				<el-table ref="multipleTable" :data="tableData" class="main-table" tooltip-effect="dark"
					style="width: 100%; font-size: 16px; color: #1a1617" :header-cell-style="{ color: '#333333' }"
					@selection-change="handleSelectionChange" v-loading="isLoading.table" border>
					<el-table-column type="selection" width="55" align="center">
					</el-table-column>
					<el-table-column :label="$t('Picture')" width="120" align="center">
						<template slot-scope="scope">
							<viewer>
								<img height="100"
									:src="`http://hwimg.xmvogue.com/thumb/${scope.row.item_no}.jpg?x-oss-process=style/800`"
									class="mr-3" alt="..." style="cursor: pointer" />
							</viewer>
						</template>
					</el-table-column>

					<el-table-column :label="$t('Product Barcode')" width="150" align="center">
						<template slot-scope="scope">
							<!-- <a @click="toGoodsInfoPage(scope.row.item_no)">{{ scope.row.item_no }}</a> -->
							{{ scope.row.item_no }}
						</template>
					</el-table-column>

					<el-table-column prop="GoodsName" :label="$t('Name')" align="center">
						<template slot-scope="scope">
							<a @click="toGoodsInfoPage(scope.row.item_no)">{{
                scope.row.GoodsName
              }}</a>
						</template>
					</el-table-column>

					<el-table-column :label="$t('Certificate')" align="center">
						<template slot-scope="scope">
							<img width="30" :src="item" v-for="(item, index) in scope.row.file_type_icon_list_arr"
								:key="index" />
						</template>
					</el-table-column>

					<el-table-column :label="$t('Purchase Price')" align="center" width="120">
						<template slot-scope="scope">
							{{ $t("Price symbol") }}{{ scope.row.price.toFixed(2) }}
						</template>
					</el-table-column>

					<el-table-column :label="$t('original price')" align="center" width="120">
						<template slot-scope="{row}">
							{{ $t("Price symbol") }}{{row.sell_price.toFixed(2) }}
						</template>
					</el-table-column>

					<el-table-column :label="$t('Quantity')" align="center" width="190">
						<template slot-scope="scope">
							<!-- <el-input-number v-model="scope.row.num" :min="scope.row.spe" :step="scope.row.spe"
								size="mini" @change="handleChange(scope.row)"></el-input-number> -->
							<el-input-number v-model="scope.row.num" :min="scope.row.spe" :step="scope.row.spe" :max="scope.row.us_num"
								size="mini" @change="
                  (currentValue, oldValue) =>
                    handleChange(currentValue, oldValue, scope.row)
                ">
							</el-input-number>
						</template>
					</el-table-column>

					<el-table-column :label="$t('Subtotal')" align="center" width="120">
						<template slot-scope="scope">
							{{ $t("Price symbol") }}{{ scope.row.total.toFixed(2) }}
						</template>
					</el-table-column>

					<el-table-column :label="$t('Event')" align="center" width="150">
						<template slot-scope="{row}">
							<span v-if="row.dis_id > 0">{{$t('Discount activities')}}</span>
							<span v-else>{{$t('No activity')}}</span>
						</template>
					</el-table-column>

					<el-table-column prop="created_at" :label="$t('Count Down')" align="center">
						<template slot-scope="{ row }">
							<countdown :end-time="row.endTime" style="color: red">
								<span slot="process" slot-scope="{ timeObj }">
									{{ +timeObj.h + +timeObj.d * 24 }}h:{{ timeObj.m }}m:
									{{ timeObj.s }}s
								</span>
								<span slot="finish">Done!</span>
							</countdown>
						</template>
					</el-table-column>

					<el-table-column :label="$t('Operation')" align="center" width="120">
						<template slot-scope="scope">
							<!-- <el-button
                size="mini"
                type="warning"
                style="font-size: 16px"
                @click="deleteRow(scope.row)">{{ $t("Delete") }}</el-button> -->

							<a class="el-icon-delete-solid" @click="deleteRow(scope.row)"></a>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>

		<div style="text-align: center; height: 150px" ref="footer">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="pages.currentPage" :page-sizes="[10, 20, 50, 100, 500, 800]" :page-size="pages.size"
				layout="total, sizes, prev, pager, next, jumper" :total="pages.total">
			</el-pagination>
		</div>

		<!-- 显示导入 -->
		<el-dialog :title="$t('Import Product to Shopping Cart')" :visible.sync="dialogVisible" width="30%"
			:before-close="handleClose" :show-close="false" :resize="false">
			<!-- <span>购物车导入</span> -->
			<div class="disDiv">
				<textarea class="textareaInput" :placeholder="$t('code,quantity')" v-model="textarea"></textarea>
			</div>

			<div class="respMessList" v-show="importGoodsDataSuccess.length > 0">
				<p style="
            height: 30px;
            line-height: 30px;
            text-indent: 1em;
            background: #218da0;
            color: white;
          ">
					Success：
				</p>
				<el-alert :title="item" v-for="(item, index) in importGoodsDataSuccess" :key="index" type="success"
					style="margin-top: 5px"></el-alert>
			</div>

			<!-- <div class="respMessList" v-show="importGoodsDataError.length>0"> -->
			<div class="respMessList" v-show="importGoodsDataError.length > 0">
				<p style="
            height: 30px;
            line-height: 30px;
            text-indent: 1em;
            background: brown;
            color: white;
          ">
					Error：
				</p>
				<el-alert :title="item" v-for="(item, index) in importGoodsDataError" :key="index" type="warning"
					style="margin-top: 5px"></el-alert>
			</div>

			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">{{ $t("Close") }}</el-button>
				<el-button type="primary" class="xmvogueColor" @click="submitInerCart" v-show="submitOrderIng">
					{{ $t("Submit") }}
				</el-button>

				<el-button type="primary" class="xmvogueColor" v-show="submitOrderIng === false">
					<i class="el-icon-loading"></i>
				</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import cart from "./js/index";

export default cart
</script>
<style lang="scss" scoped>
	//显示消息列表
	.respMessList {
		margin-top: 10px;
		height: 200px;
		overflow: auto;
		border: 1px solid #218da0;
	}

	.cartTableList {
    width: 86%;
    margin: 0 auto 20px;
  }

	.xmvogueColor {
		background: #218da0;
		border-color: #218da0;
	}

	.bdXmColor {
		border-color: #218da0;
		font-size: 16px;
	}

	.el-dialog__header {
		border-bottom: 1px solid #218da0;
	}

	.el-textarea__inner,
	// .disDiv,
	.el-dialog {
		border-radius: 10px;
	}

	.textareaInput {
		border: 1px solid #218da0;
		border-radius: 5px;
		width: 100%;
		height: 180px;
		resize: none;
	}

	.el-textarea__inner {
		border: none;
		height: 198px;
	}

	//新的样式
	.showCartListInfo {
		padding: 5px;
		font-size: 20px;
		position: absolute;
		bottom: 0;
		background: rgb(33, 141, 160);
		color: white;
		border-radius: 10px;
		z-index: 99999;
		width: 80%;
	}

	.showCartListInfo2 {
		padding: 8px;
		margin-top: 5px;
		border-bottom: 1px solid rgb(33, 141, 160);
	}

	.totalPirce1 {
		margin-left: 11%;
	}

	//=========
	.showDivTotal {
		margin-top: 10px;
		font-size: 20px;
		color: rgb(255, 153, 0);
	}

	.hiddenDivTotal {
		display: none;
	}
</style>
